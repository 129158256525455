
import {defineComponent, ref} from "vue";
import FileShareService from "@/core/services/FileShareService";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import EntityView from "@/components/base/common/EntityView.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Swal from "sweetalert2";
import BaseForm from "@/components/base/form/BaseForm.vue";
import {Field} from "vee-validate";
import {useToast} from "vue-toast-notification";

export default defineComponent({
  name: "ViewFileShare",
  components: {Field, BaseForm, QuickAction, EntityView, EntityLoading},
  props: {
    inputs: {type: Object, required: true},
  },
  setup(props) {
    const state = ref<any>({loading: true, data: {}, showData: false, password: ''})
    const load = (inputs) => {
      FileShareService.accessFile(inputs.fileId, state.value.password).then(res => {
        state.value.loading = false;
        state.value.data = res;
      })
    }
    const submit = () => {
      if (state.value.password) {
        state.value.showData = true;
        load(props.inputs)
      }else {
        useToast().warning('Please input password', {position: 'top-right'})
      }
    }
    return {
      submit,
      state,
      load,
    }
  },
  watch: {
    inputs() {
      this.state.showData = false
    }
  },
  methods: {

    onDownloadPdf() {
      Swal.showLoading()
      FileShareService.download(this.inputs.fileId, this.state.password).then(res => {
        let fileName = res.headers["content-disposition"].split("filename=")[1];
        let blob = new Blob(
          [res.data]
        );
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      }).finally(() => {
        Swal.close()
      })
    }
  }
})
